import React from 'react';

const DesignServiceComponent = ({handleOpenModal}) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#fff',
    margin: 0,
    padding: 100,
    marginBottom: 200
  };

  const headingStyle = {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '60px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '10px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
  };

  const freeTrialButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#000',
    color: '#fff',
  };

  const sendEmailButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#d3d3d3',
    color: '#000',
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Get your trusted UI/UX<br/>design service today.</h1>
      <div style={buttonContainerStyle}>
        <button style={freeTrialButtonStyle} onClick={handleOpenModal}>START FREE TRIAL</button>
        <button style={sendEmailButtonStyle} onClick={handleOpenModal}>SEND EMAIL</button>
      </div>
    </div>
  );
};

export default DesignServiceComponent;
