import React from "react";

const PricingCards = () => {
  const cardStyles = {
    borderRadius: "10px",
    padding: "20px",
    textAlign: "left",
    maxWidth: "320px",
    margin: "0 10px",
    flex: "1",
  };

  const headerStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const priceStyles = {
    fontSize: "28px",
    fontWeight: "bold",
    margin: "15px 0",
  };

  const badgeStyles = {
    fontSize: "12px",
    padding: "5px 10px",
    borderRadius: "12px",
    fontWeight: "bold",
    display: "inline-block",
  };

  const starterBadge = {
    ...badgeStyles,
    backgroundColor: "#eaeaea",
    color: "#555",
  };

  const eliteBadge = {
    ...badgeStyles,
    backgroundColor: "#000",
    color: "#fff",
  };

  const cards = [
    {
      title: "Expert UI Design",
      description:
        "Perfect for startups and small businesses. Senior professional work result with less than a junior salary.",
      price: "$2,250 /mo.",
      backgroundColor: "#eaeaea", // Light gray
      badge: { text: "Starter", style: starterBadge },
      features: [
        "UI design",
        "Website design",
        "Mobile app Design",
        "Responsive Design",
        "Wireframe and low fidelity ideation",
        "Full-time & Unlimited requests",
        "1-2 day revisions",
        "Iterative Polishing",
        "Dev-ready handover",
        "Pause or Cancel Anytime",
      ],
    },
    {
      title: "Lead UI/UX Design",
      description:
        "Perfect for teams and larger businesses who want end-to-end seamless digital experience refined based on insights to grow.",
      price: "$3,995 /mo.",
      backgroundColor: "#000", // Black
      badge: { text: "Elite", style: eliteBadge },
      features: [
        "Everything in Starter +",
        "Super Senior Design Expertise",
        "Research, Analysis and Benchmarking",
        "MENA Expertise",
        "Usability Testing",
        "Wireframe and Prototyping",
        "1:1 async communication + Weekly sync call",
        "Framer no-code website development",
        "Visual identity and branding design",
        "Pause or Cancel Anytime",
      ],
      textColor: "#fff", // White text for better contrast
    },
    {
      title: "Our Entire Design Team",
      description:
        "Custom project, SOW, dedicated project management, for businesses striving best results in the fastest way.",
      price: "$11,495 /mo.",
      backgroundColor: "#f9f9f9", // Light gray
      badge: null,
      features: [
        "Everything in Elite +",
        "Entire team at your service",
        "Workshop & Training sessions",
        "Onboarding",
        "Progress tracking & Leadership Presentation",
        "Unlimited possibilities",
        "Pause or Cancel Anytime",
      ],
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "48px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        Expert design for every budget
      </h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {cards.map((card, index) => (
          <div
            key={index}
            style={{
              ...cardStyles,
              backgroundColor: card.backgroundColor,
              color: card.textColor || "#000", // Default text color is black
            }}
          >
            <div style={headerStyles}>{card.title}</div>
            {card.badge && (
              <span style={card.badge.style}>{card.badge.text}</span>
            )}
            <p style={{ fontSize: "14px", margin: "10px 0" }}>
              {card.description}
            </p>
            <div style={priceStyles}>{card.price}</div>
            <ul
              style={{
                paddingLeft: "20px",
                lineHeight: "1.6",
                fontSize: "14px",
                margin: "15px 0 0 0",
              }}
            >
              {card.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingCards;
