import React from "react";
import Logo from '../assets/logo.png';

const HeroSection = ({ onDiscoverClick }) => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "80px 20px",
        textAlign: "center",
        fontFamily: "'Inter', sans-serif",
        color: "#000",
      }}
    >
      <img src={Logo} alt="Logo" style={{ marginBottom: "30px", width: 120 }} />
      <h1
        style={{
          fontSize: "48px",
          fontWeight: "700",
          lineHeight: "1.2",
          marginBottom: "20px",
        }}
      >
        Every experience <br /> tells stories.
      </h1>
      <p
        style={{
          fontSize: "20px",
          fontWeight: "400",
          lineHeight: "1.6",
          marginBottom: "40px",
          maxWidth: "600px",
        }}
      >
        We transform ideas into narratives where people, technology, & emotions
        intertwine seamlessly.
      </p>
      <button
        onClick={onDiscoverClick}
        style={{
          padding: "12px 30px",
          fontSize: "16px",
          fontWeight: "500",
          backgroundColor: "#000",
          color: "#fff",
          textDecoration: "none",
          borderRadius: "50px",
          display: "inline-flex",
          alignItems: "center",
          gap: "8px",
          border: "none",
          cursor: "pointer",
        }}
      >
        Discover Our Approach
        <span
          style={{
            display: "inline-block",
            transform: "rotate(45deg)",
            borderTop: "2px solid #fff",
            borderRight: "2px solid #fff",
            width: "10px",
            height: "10px",
          }}
        ></span>
      </button>
    </section>
  );
};

export default HeroSection;
