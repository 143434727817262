import React, {useRef, useState} from "react";
import HeroSection from "./components/HeroSection";
import Demo from "./components/Demo";
import InfoCard from "./components/InfoCard";
import ComparisonTable from "./components/ComparisonTable";
import PricingCards from "./components/PricingCards";
import Services from "./components/Services";
import CTASection from "./components/CTASection";
import Modal from "./components/Modal";
import "./App.css";

function App() {

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const comparisonTableRef = useRef(null);

  const handleDiscoverClick = () => {
    comparisonTableRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
      <HeroSection  onDiscoverClick={handleDiscoverClick}/>
      <Demo />
      <Services />
      <InfoCard handleOpenModal={handleOpenModal}/>
      <div ref={comparisonTableRef}>
        <ComparisonTable />
      </div>
      <PricingCards />
      <CTASection handleOpenModal={handleOpenModal}/>
    </div>
  );
}

export default App;
