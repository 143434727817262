import React, { useState, useRef } from 'react';
import demoVideo from '../assets/demo.mp4'; // Adjust the path as necessary

const SimpleVideoPlayer = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPauseButton, setShowPauseButton] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setShowPauseButton(false);
      } else {
        videoRef.current.play();
        setShowPauseButton(true);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleMouseMove = () => {
    if (isPlaying) {
      setShowPauseButton(true);

      // Hide pause button after 2 seconds of no movement
      const timeout = setTimeout(() => {
        setShowPauseButton(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  };

  const handleMouseLeave = () => {
    setShowPauseButton(false);
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const videoContainerStyle = {
    position: 'relative',
    borderRadius: '20px',
    overflow: 'hidden',
    maxWidth: '360px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: 50,
  };

  const videoStyle = {
    width: '100%',
    borderRadius: '20px',
    cursor: 'pointer', // Add cursor pointer for clicking
  };

  const buttonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '20px',
    padding: '15px 20px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const playButtonStyle = {
    ...buttonStyle,
    opacity: 1, // Always visible
  };

  const pauseButtonStyle = {
    ...buttonStyle,
    opacity: showPauseButton ? 1 : 0,
    transition: 'opacity 0.5s ease', // Smooth fade for pause button
  };

  const iconStyle = {
    marginRight: '8px',
    fontSize: '16px',
  };

  return (
    <div style={containerStyle}>
      <div
        style={videoContainerStyle}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={togglePlayPause} // Add togglePlayPause on container click
      >
        <video
          ref={videoRef}
          style={videoStyle}
          src={demoVideo}
          controls={false} // Disable default controls
        ></video>
        <button
          style={isPlaying ? pauseButtonStyle : playButtonStyle}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering video container's onClick
            togglePlayPause();
          }}
        >
          <span style={iconStyle}>{isPlaying ? '⏸️' : '▶️'}</span>
          {isPlaying ? 'Pause' : 'Play'}
        </button>
      </div>
    </div>
  );
};

export default SimpleVideoPlayer;
