import React, { useState } from "react";

const Services = () => {
  const [hoveredIndex, setHoveredIndex] = useState(0); // Default hover state for the first item

  const services = [
    "Website",
    "UI/UX Design",
    "Brand Design",
    "Graphic Design",
    "Motion Design",
  ];

  return (
    <section style={{
      display: "flex",
      flexDirection: "column",
      padding: "60px 20px",
      fontFamily: "'Inter', sans-serif",
      color: "#000",
      maxWidth: "1200px",
      margin: "0 auto", // Center content horizontally
    }}>
      {/* Title Section */}
      <div style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "40px",
      }}>
        {/* Design Element */}
        <div style={{
          height: "2px",
          width: "40px",
          backgroundColor: "#000",
        }}></div>
        {/* Title */}
        <div style={{
          fontSize: "16px",
          fontWeight: "500",
          textTransform: "uppercase",
          letterSpacing: "2px",
        }}>
          Services
        </div>
      </div>

      {/* Section Title + Right Section */}
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch", // Make both sections share equal height
        gap: "40px",
        marginLeft: 100
      }}>
        {/* Section Title */}
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
          {/* Question */}
          <h2 style={{
            fontSize: "48px",
            fontWeight: "700",
            lineHeight: "1.4",
            marginBottom: "20px",
          }}>
            How do we narrate <br /> your story?
          </h2>

          </div>
          {/* Right Section */}
          <div style={{
            flex: 1,
            fontSize: "16px",
            color: "#888",
            lineHeight: "1.6",
            display: "flex",
            margin: 20
          }}>
            <p style={{
              margin: 0,
            }}>
              We generate innovation in all<br/>economic sectors and build products<br/>that put technology at the service<br/>of people.
            </p>
          </div>
          </div>
          <div>
          <div>

          {/* Services List */}
          <ul style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: 50,
            marginLeft: 100
          }}>
            {services.map((service, index) => (
              <li
                key={index}
                onMouseEnter={() => setHoveredIndex(index)} // Keep hovered index active
                style={{
                  fontSize: "64px",
                  color: index === hoveredIndex ? "#000" : "#ccc",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  transition: "color 0.3s ease, font-weight 0.3s ease",
                }}
              >
                <span style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: index === hoveredIndex ? "#000" : "#aaa",
                  transition: "color 0.3s ease",
                  marginTop: -25,
                  paddingRight: 30
                }}>
                  0{index + 1}
                </span>
                {service}
              </li>
            ))}
          </ul>
        </div>


      </div>
    </section>
  );
};

export default Services;
