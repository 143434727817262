import React from "react";

const CenteredCard = ({handleOpenModal}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 100
      }}
    >
      <div
        style={{
          maxWidth: "720px",
          padding: "100px 150px",
          backgroundColor: "#000", // Black card background
          color: "#fff", // White text
          borderRadius: "15px", // Rounded corners
          textAlign: "center",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)", // Subtle shadow for card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            margin: "0 0 15px 0",
          }}
        >
          Pay a junior rate, <br />
          enjoy pro-level designs
        </h1>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "1.6",
            marginBottom: "20px",
          }}
        >
          No delays in hiring talent, no frustration with design quality, no
          unprofessional workflows, no benefit or bonus packages—just
          exceptional service, no juniors.
        </p>
        <button
          style={{
            padding: "12px 25px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#000", // Black text
            backgroundColor: "#00e6c3", // Button color
            border: "none",
            borderRadius: "5px", // Rounded button
            cursor: "pointer",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)", // Button shadow for depth
          }}
          onClick={handleOpenModal}
        >
          START FREE TRIAL
        </button>
      </div>
    </div>
  );
};

export default CenteredCard;
