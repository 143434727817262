import React from "react";

const ComparisonTable = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "48px",
          fontWeight: "bold",
          marginBottom: "30px",
          color: "#000",
          textAlign: "center",
        }}
      >
        Side by side, <br />
        it’s an easy choice
      </h1>
      <div
        style={{
          width: "100%",
          maxWidth: "800px", // Limit table width
          backgroundColor: "#f5f5f5",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontSize: "14px",
          }}
        >
          <thead
            style={{
              backgroundColor: "#f5f5f5",
              textAlign: "left",
              fontWeight: "bold",
              borderBottom: "2px solid #e0e0e0",
            }}
          >
            <tr>
              <th
                style={{
                  padding: "15px",
                  textAlign: "left",
                }}
              >
                Feature
              </th>
              <th
                style={{
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                made.by.studios
              </th>
              <th
                style={{
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                In-house Designer
              </th>
            </tr>
          </thead>
          <tbody>
            {[
              { feature: "Monthly Payments", studio: true, inHouse: true },
              { feature: "No Commitment", studio: true, inHouse: false },
              { feature: "Comprehensive Expertise", studio: true, inHouse: false },
              { feature: "Quick Recruitment & Onboarding", studio: true, inHouse: false },
              { feature: "No Bonus, Insurance, Visa, etc.", studio: true, inHouse: false },
              { feature: "No Management Headaches", studio: true, inHouse: false },
              { feature: "No Multiple Hirings", studio: true, inHouse: false },
              { feature: "Full-Time Engagement", studio: true, inHouse: true },
            ].map((row, index) => (
              <tr
                key={index}
                style={{
                }}
              >
                <td
                  style={{
                    padding: "15px",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {row.feature}
                </td>
                <td
                  style={{
                    padding: "15px",
                    textAlign: "center",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {row.studio ? "✔️" : "✖️"}
                </td>
                <td
                  style={{
                    padding: "15px",
                    textAlign: "center",
                    borderBottom: "1px solid #e0e0e0",
                  }}
                >
                  {row.inHouse ? "✔️" : "✖️"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ComparisonTable;
