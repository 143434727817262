import React from "react";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
        style={{
          backgroundColor: "#fff",
          color: "#000",
          padding: "30px",
          borderRadius: "10px",
          maxWidth: "500px",
          width: "100%",
          textAlign: "center",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold" }}>Contact Us</h2>
        <br/>
        <br/>
        <p style={{ fontSize: "14px", lineHeight: "1.6" }}>
          <b>Email</b>: parassrest@gmail.com
          <br />
          <b>Phone</b>: +977-9849743676
          <br />
          <b>Address</b>: Baluwatar, Kathmandu, Nepal
        </p>
        <br/>
        <br/>
        <button
          onClick={onClose}
          style={{
            padding: "10px 20px",
            fontSize: "14px",
            backgroundColor: "#000",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
